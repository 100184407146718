<template>
  <div class="title-bar items-start">
    <div class="title text-mono-black">
      <slot />
    </div>
    <div class="title-bar-actions">
      <slot name="button" />
    </div>
  </div>
</template>
